import logo from './logo.svg';

import './polyfills';

import './App.css';

import '@rainbow-me/rainbowkit/styles.css';

import { connectorsForWallets } from '@rainbow-me/rainbowkit';

import {
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import {
  chain,
  configureChains,
  createClient,
  WagmiConfig,
} from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import {  YourApp } from './ConnectButton';



import {
  injectedWallet,
  rainbowWallet,
  walletConnectWallet,
  metaMaskWallet
} from '@rainbow-me/rainbowkit/wallets';


const { chains, provider } = configureChains(
  [chain.mainnet],
  [
    alchemyProvider({ apiKey: 'Q2jyuYfYMEpDvHR6oCs2Nnh-Wbh5llDk' }),
    publicProvider()
  ]
);

// const { connectors } = getDefaultWallets({
//   appName: 'My RainbowKit App',
//   chains
// });

const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [
      injectedWallet({ chains }),
      rainbowWallet({ chains }),
      walletConnectWallet({ chains }),
      metaMaskWallet({ chains }),
    ],
  },
]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
})

const App = () => {
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains}>
        <YourApp />
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

export default App;
